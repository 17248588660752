.user-menu {
  background-color: white;
  position: absolute;
  left: auto;
  right: 1em;
  top: 4em;
  border: 1px solid grey;
  border-radius: 0.5rem;
}

.user-menu__nav {
  display: flex;
  flex-direction: column;
}

.user-menu__item {
  padding: 1em;
}

.user-menu__divider {
  border-top: 1px solid grey;
}

.user-menu__invite {
  text-decoration: none;
  color: black;
  margin-left: 0.5em;
}

.user-menu__logout {
  border: 0;
  background: none;
  cursor: pointer;
}

.user-menu--clickable:hover {
  border-radius: 0.4rem;
  background-color: var(--light-green-color);
}
