.project-navbar {
  display: flex;
  border-bottom: 1px solid var(--weak-line-color);
  list-style: none;
}

.project-navbar__item {
  display: list-item;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.project-navbar__item:hover {
  border-color: #dee2e6 #dee2e6 #fff;
}

.project-navbar__item--active {
  margin-bottom: -1px;
  border-color: #dee2e6 #dee2e6 #fff;
}
