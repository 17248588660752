.warning-box {
  padding: 0.5em;
  margin: 0.5em;
  background-color: rgb(255 42 81 / 10%);
  border-left: 3px red solid;
  display: flex;
  align-items: center;
  align-content: center;
}

.warning-icon {
  margin-right: 0.4em;
  margin-bottom: 0.1em;
}
