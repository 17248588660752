.search-area {
  width: 70%;
  margin: 1rem;
}

.search-area__results {
  width: 66%;
  margin: 3px;
  padding: 0.5rem;
  position: absolute;
  font-size: 16px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
