.open-discussions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.2em;
  padding: 0.6em;
}

.open-discussions--green {
  background-color: var(--light-green-color);
}

.open-discussions--yellow {
  background-color: var(--light-yellow-color);
}

.open-discussions--blue {
  background-color: var(--light-blue-color);
}

.open-discussions__icon {
  margin-right: 0.4em;
  margin-bottom: 0.1em;
}
