.selected-file-list {
  list-style: none;
  padding: 1em;
}

.selected-file-link {
  cursor: pointer;
  margin: 0.2em;
  font-weight: 500;
  border: 0 solid black;
  background: 0;
}

.selected-file-link:hover {
  text-decoration: underline;
}
