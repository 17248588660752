:root {
  --main-link-color: #0969da;
  --muted-text-color: #57606a;
  --weak-line-color: hsl(210deg 18% 87%);
  --light-yellow-color: #fff6e7;
  --light-blue-color: #eafcff;
  --light-green-color: #ebfff4;
  --code-red-color: #dd4a68;
  --code-green-color: #690;
  --code-blue-color: #07a;
  --font-normal-weight: 400;
  --font-heavy-weight: 700;

  font-size: 0.75em;
}

@media (min-width: 800px) {
  :root {
    font-size: 0.875em;
  }
}

@media (min-width: 1200px) {
  :root {
    font-size: 0.9em;
  }
}

body {
  min-width: 320px;
  background-color: white;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  color: black;
  font-family: -apple-system, Roboto, sans-serif;
}

a:visited {
  color: var(--main-link-color);
}

.nav-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.big-button {
  font-size: 1.1rem;
  cursor: pointer;
  margin: 0 0.1em;
  padding: 0.6em 1em;
  font-weight: var(--font-normal-weight);
  border-radius: 0.3em;
  border: 0;
  transition: background-color 0.1s ease;
}

.login-button {
  background-color: #6ece9d;
}

.login-button:hover {
  background-color: #91dab4;
}

.title-link {
  font-size: 1.8rem;
  font-weight: var(--font-heavy-weight);
  line-height: 1.5;
  text-decoration: none;
  color: #2f363d;
}

.title-link:hover {
  text-decoration: underline;
}

.project-run-view {
  display: flex;
  padding: 1em;
}

.project-file-list {
  width: 50%;
  margin-right: 0.5em;
  padding: 0.5em;
  border-radius: 0.3em;
}

.project-file-selected {
  width: 50%;
  padding: 0.5em;
  border-radius: 0.3em;
}

@media only screen and (max-width: 500px) {
  .project-run-view {
    flex-direction: column;
  }

  .project-file-list {
    width: 100%;
  }

  .project-file-selected {
    width: 100%;
  }
}

.project-file-no-selected {
  text-align: center;
}

.logout-button {
  align-self: flex-end;
  font-size: 16px;
  font-weight: var(--font-heavy-weight);
  color: white;
  background-color: darkred;
  appearance: none;
  padding: 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5em;
  padding: 10px;
  margin: 10px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px 10px 10px;
}

.input-field {
  font-size: 1.1rem;
  display: block;
  width: 100%;
  height: 35px;
  padding: 0;
  padding-left: 4px;
  margin: 0;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.muted-span {
  font-size: 1.1rem;
  color: var(--muted-text-color);
}

.main-form {
  display: flex;
}

.top-navigation {
  display: flex;
}

.top-navigation__back-link {
  padding: 1em;
}

.project-list__list {
  padding: 0.1em;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
