.event-image {
  margin: 0.3rem;
  border: 1px solid black;
  border-radius: 3px;
}

.event-image__small {
  max-width: 230px;
  max-height: 95px;
  width: auto;
  height: auto;
}

.event-image__button {
  cursor: pointer;
  padding: 0;
  border: 0;
  background: 0;
}

.event-image__modal {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(0deg 0% 15% / 60%);
}
