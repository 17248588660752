.timeline-run {
  margin: 1em;
  padding: 0;
  list-style-type: none;
}

.timeline-run__change {
  display: flex;
  gap: 0.5rem;
  margin-left: 0.3em;
  margin-bottom: 1em;
  flex-wrap: wrap;
}

.timeline-run__faded-text {
  text-decoration: line-through;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.green {
  color: green;
}
