.file-list-item {
  display: flex;
  padding: 0.3em;
  margin-top: 0.3em;
  margin-bottom: 0.4em;
  border-radius: 0.3em;
}

.project-file-list__top-ul {
  padding-left: 0;
}

.project-file-list__ul {
  padding-left: 1.3em;
}

.file-list-item--selected {
  background-color: rgb(0 0 230 / 10%);
}

.file-list-item__icon {
  margin-right: 0.5em;
}

.file-list-item__name {
  margin-right: 0.5em;
  align-self: center;
}

.colored-pill__container {
  align-self: center;
  margin-right: 0.2em;
  margin-left: auto;
  white-space: nowrap;
}

.colored-pill {
  padding: 0.3em;
  margin-right: 0.5em;
  border-radius: 0.8em;
}

.colored-pill--blue {
  background-color: var(--light-blue-color);
}

.colored-pill--green {
  background-color: var(--light-green-color);
}
