.run-details {
  font-size: 1.1rem;
  padding: 0.5em;
  margin: 0.3em;
  cursor: pointer;
}

.run-details__list {
  list-style: none;
}
