.main-container {
  background-color: white;
  display: flex;
  outline: none;
  height: 100vh;
  width: 100%;
  flex-direction: column;
}

.main-main {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

@media only screen and (max-width: 500px) {
  .main-main {
    padding: 0;
  }
}

.main-header {
  padding: 1em;
  display: flex;
  justify-content: space-between;
}
