.start-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 4em 0 0;
}

.start-h1 {
  max-width: 512px;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  letter-spacing: -2px;
  margin-bottom: 1em;
}

.start-p {
  max-width: 512px;
  margin-bottom: 48px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #727272;
  text-align: center;
}

.start-buttons {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  gap: 2em;
  padding: 2em;
  background-color: #d0e5fe;
}

.start-email-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  margin: 1em;
}

.start-email-form > button {
  margin: 1em;
}

.start-live-demo {
  color: black;
  text-decoration: none;
  background-color: #6ece9d;
}

.start-live-demo:visited {
  color: black;
  text-decoration: none;
}

.start-live-demo:hover {
  background-color: #91dab4;
}
