.project-list-item {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 1em;
}

.project-list-item__title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.2em;
}

.project-list-item__title-link {
  text-decoration: none;
  color: var(--main-link-color);
}

.project-list-item__info {
  display: flex;
  flex-direction: column;
}

.project-list-item__repo {
  text-align: end;
  margin-right: 1em;
}
