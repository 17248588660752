.copy-field {
  display: flex;
  padding: 1rem;
}

.copy-field__input {
  margin: 0.15rem;
  margin-right: 0.3rem;
  border: 1px solid black;
  border-radius: 3px;
  font-family: monospace;
  font-size: 1.3em;
  text-align: center;
}

.copy-field__button {
  margin: 0.15rem;
  cursor: pointer;
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  transition: background-color 0.1s ease;
}

.copy-field__button:hover {
  background-color: var(--light-green-color);
}
