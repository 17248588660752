.project-dropdown {
  display: block;
  margin-top: 5px;
  padding: 0.4rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-weight: 500;
}

.project-dropdown__label {
  display: block;
  padding: 0.2rem;
  font-size: 15px;
}
