.comment-form {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.comment-area {
  resize: none;
  box-shadow: inset 0 2px 4px 0 hsl(0deg 0% 0% / 8%);
  margin: 0.5em;
}
